import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2df8d0ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "ID"
}
const _hoisted_3 = ["id", "rows", "placeholder"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      class: "form-control",
      id: _ctx.ID,
      rows: _ctx.rows,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event))),
      placeholder: _ctx.placeholder
    }, _toDisplayString(_ctx.modelValue), 41, _hoisted_3)
  ]))
}
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    content: _ctx.content,
    placement: _ctx.placement,
    effect: _ctx.effect
  }, {
    default: _withCtx(() => [
      _createElementVNode("button", {
        type: "button",
        class: _normalizeClass(_ctx.btnClass),
        onClick: _cache[0] || (_cache[0] = (...args) => (_ctx.btnClick && _ctx.btnClick(...args)))
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }, 8, ["content", "placement", "effect"]))
}
import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/system/dictionary",
    // 层级选择器
    levelSelect(query: any) {
        return requests.post(this.modulesUrl + "/levelSelect", query);
    },
    // 字典选择器
    dictionarySelect(query: any) {
        return requests.post(this.modulesUrl + "/dictionarySelect", query);
    },
};

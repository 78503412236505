import { decrypt } from "@/utils/cryptojs";
import API from "@/api/auth";

export default {
    setToken: (state: any, payload: string): void => {
        state.token = payload;
        localStorage.setItem("token", payload);
    },
    setUserInfo: (state: any, payload: any): void => {
        state.userInfo = payload ? JSON.parse(decrypt(payload)) : null;
        localStorage.setItem("userInfo", payload);
    },
    setPageRole: (state: any, payload: string): void => {
        state.pageRole = payload;
    },
    getPageRole: async (state: any, payload: any): Promise<void> => {
        const path = payload;
        if (state.pageRole[path] && state.pageRole[path].length > 0) return;
        await API.getPageRole({ path }).then((res: any) => {
            const data = res.data ? JSON.parse(decrypt(res.data)) : null;
            //console.log(data);
            if (data.length > 0) {
                state.pageRole[path] = data;
            }
        });
    },
};

(Array.prototype as any).remove = function (value: any) {
    const index = this.indexOf(value);
    if (index > -1) {
        this.splice(index, 1);
    }
    return index;
};

//移除对象数组，匹配唯一key
(Array.prototype as any).removeByKey = function (key: any, val: any) {
    const index = this.findIndex((value: any) => value[key] === val);
    if (index > -1) {
        this.splice(index, 1);
    }
    return index;
};

//对象数组转map
(Array.prototype as any).toMap = function (key: any) {
    const map = new Map();
    this.forEach((v: any) => map.set(v[key], v));
    return map;
};

import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11b2bca1"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["width", "height"]
const _hoisted_2 = ["xlink:href"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", {
    class: _normalizeClass(_ctx.svgClass),
    width: _ctx.w,
    height: _ctx.h,
    "aria-hidden": "true"
  }, [
    _createElementVNode("use", {
      "xlink:href": '#icon-' + _ctx.name
    }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}
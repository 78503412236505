import { Options, Vue } from "vue-class-component";
@Options({
    name: "app-ellipsis",
    props: {
        row: {
            type: Number,
            default: 1,
        },
        hoverTip: {
            type: Boolean,
            default: false,
        },
        content: {
            type: String,
            default: "",
        },
    },
})
//超出指定行数自动隐藏文字
export default class Ellipsis extends Vue {}

import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";

@Options({
    name: "app-input",
    props: {
        modelValue: String,
        label: String,
        placeholder: String,
        rows: {
            type: String,
            default: "4",
        },
    },
    emits: ["update:modelValue"],
})
export default class Textarea extends Vue {
    private ID: string = uuidv4();
    private modelValue: string;
    private label: string;
    private rows: string;

    public onValueChange(event: any) {
        this.$emit("update:modelValue", event.target.value);
    }
}

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max, (i) => {
      return (_openBlock(), _createBlock(_component_svg_icon, {
        style: _normalizeStyle({color: _ctx.color}),
        key: i,
        name: i <= _ctx.val ? 'star' : 'star-outline',
        size: _ctx.size
      }, null, 8, ["style", "name", "size"]))
    }), 128))
  ]))
}
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-800c243c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "checked"]
const _hoisted_2 = ["for"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'icheck-primary': _ctx.type === 'icheck', 'form-check': _ctx.type === 'default', 'custom-control custom-checkbox': _ctx.type === 'custom'})
  }, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.ID,
      class: _normalizeClass({'form-check-input' : _ctx.type === 'default', 'custom-control-input': _ctx.type === 'custom'}),
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event)))
    }, null, 42, _hoisted_1),
    _createElementVNode("label", {
      for: _ctx.ID,
      class: _normalizeClass({'form-check-label':  _ctx.type === 'default', 'custom-control-label': _ctx.type === 'custom'})
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 10, _hoisted_2)
  ], 2))
}
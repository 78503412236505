import router from "@/router";

export default {
    setToken: (context: any, payload: string): void => {
        context.commit("setToken", payload);
    },
    setUserInfo: (context: any, payload: any): void => {
        context.commit("setUserInfo", payload);
    },
    getPageRole: (context: any, payload: any): void => {
        context.commit("getPageRole", payload);
    },
    logout: (context: any): void => {
        context.commit("setToken", null);
        // context.commit('setUserInfo', null);
        context.commit("setPageRole", {});
        context.commit("ui/setAutoLogin", false, { root: true });
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        router.replace("/login");
    },
};

import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-select-star",
    props: {
        modelValue: [String, Number],
        label: String,
        max: {
            type: Number,
            default: 5,
        },
        color: {
            type: String,
            default: "red",
        },
        size: {
            type: String,
            default: "md",
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        readonly: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ["update:modelValue", "update:changeValue"],
})
export default class SelectStar extends Vue {
    public onValueChange(value: any) {
        this.$emit("update:modelValue", value);
        this.$emit("update:changeValue", value);
    }
}

import { Options, Vue } from "vue-class-component";
import Modal from "@/components/modal/modal.vue";

@Options({
    name: "app-alert",
    components: {
        "app-modal": Modal,
    },
})
export default class Alert extends Vue {
    private alertModalElement: any;

    private title = "未定义";
    private size = "sm";
    private loseFocusClose = true;
    private submitFunction: any = null;
    private submitAfterClose = true;
    private showSubmitButton = true;
    private showCloseButton = false;
    private submitButtonName = "确定";
    private closeButtonName = "取消";
    private message: string;
    private fixed = true;

    public async mounted(): Promise<void> {
        this.alertModalElement = this.$refs.alertModal;
    }

    public openAlert(config: any, callBack: any) {
        if (config.type === "onlyOK") {
            this.size = "sm";
            this.loseFocusClose = true;
            this.submitAfterClose = true;
            this.showSubmitButton = true;
            this.showCloseButton = false;
            this.submitButtonName = "确认";
        } else if (config.type === "onlyNO") {
            this.size = "sm";
            this.loseFocusClose = true;
            this.submitAfterClose = true;
            this.showSubmitButton = false;
            this.showCloseButton = true;
            this.closeButtonName = "取消";
        } else if (config.type === "okAndNo") {
            this.size = "sm";
            this.loseFocusClose = true;
            this.submitAfterClose = true;
            this.showSubmitButton = true;
            this.showCloseButton = true;
            this.submitButtonName = "确认";
            this.closeButtonName = "取消";
        }

        if ("title" in config) this.title = config.title;
        if ("size" in config) this.size = config.size;
        if ("fixed" in config) this.fixed = config.fixed;
        if ("message" in config) this.message = config.message;
        if ("loseFocusClose" in config) this.loseFocusClose = config.loseFocusClose;
        if ("submitAfterClose" in config) this.submitAfterClose = config.submitAfterClose;
        if ("showSubmitButton" in config) this.showSubmitButton = config.showSubmitButton;
        if ("showCloseButton" in config) this.showCloseButton = config.showCloseButton;
        if ("submitButtonName" in config) this.submitButtonName = config.submitButtonName;
        if ("closeButtonName" in config) this.closeButtonName = config.closeButtonName;

        if (callBack != undefined || callBack != null) this.submitFunction = callBack;

        this.openModal(this.fixed);
    }

    public openModal(fixed = true) {
        this.alertModalElement.openModal(fixed);
    }

    public closeModal() {
        this.alertModalElement.closeModal();
    }

    public toggleModal(fixed = true) {
        this.alertModalElement.toggleModal(fixed);
    }
}

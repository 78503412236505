import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_app_select_from_db = _resolveComponent("app-select-from-db")
  const _component_app_modal = _resolveComponent("app-modal")

  return (_openBlock(), _createBlock(_component_app_modal, {
    ref: "textareaModal",
    loading: _ctx.loading,
    title: _ctx.title,
    submitButtonName: _ctx.submitButtonName,
    size: _ctx.size,
    submitFunction: _ctx.modFunction
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_select_from_db, {
        label: _ctx.label,
        modelValue: _ctx.modValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.modValue) = $event)),
        searchApi: _ctx.selectApi
      }, null, 8, ["label", "modelValue", "searchApi"])
    ]),
    _: 1
  }, 8, ["loading", "title", "submitButtonName", "size", "submitFunction"]))
}
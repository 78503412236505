import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-59397120"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["title"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ line: _ctx.row === 1, lines: _ctx.row > 1 }),
    title: _ctx.hoverTip ? _ctx.content : null,
    style: _normalizeStyle({ '--row': _ctx.row })
  }, [
    _renderSlot(_ctx.$slots, "pre", {}, undefined, true),
    _createTextVNode(" " + _toDisplayString(_ctx.content), 1)
  ], 14, _hoisted_1))
}
import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-star",
    props: {
        val: Number,
        max: {
            type: Number,
            default: 5,
        },
        color: {
            type: String,
            default: "red",
        },
        size: {
            type: String,
            default: "mini",
        },
    },
})
export default class Star extends Vue {}

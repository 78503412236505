import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ba6f9a3a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]

export function render(_ctx, _cache) {
  const _component_app_modal = _resolveComponent("app-modal")

  return (_openBlock(), _createBlock(_component_app_modal, {
    ref: "alertModal",
    title: _ctx.title,
    size: _ctx.size,
    loseFocusClose: _ctx.loseFocusClose,
    submitFunction: _ctx.submitFunction,
    submitAfterClose: _ctx.submitAfterClose,
    showSubmitButton: _ctx.showSubmitButton,
    showCloseButton: _ctx.showCloseButton,
    submitButtonName: _ctx.submitButtonName,
    closeButtonName: _ctx.closeButtonName
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", { innerHTML: _ctx.message }, null, 8, _hoisted_1)
    ]),
    _: 1
  }, 8, ["title", "size", "loseFocusClose", "submitFunction", "submitAfterClose", "showSubmitButton", "showCloseButton", "submitButtonName", "closeButtonName"]))
}
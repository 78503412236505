import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-vue-alert",
})
export default class VueAlert extends Vue {
    private title = "确认";
    private message = "请确认该操作！";
    private show = false;
    private callback: any = null;
    private zIndex = 3000;
    private closeButtonName = "取消";
    private submitButtonName = "确定";

    public openModel() {
        this.show = true;
        this.$nextTick(() => {
            (this.$refs.modalSubmitButton as HTMLElement).focus();
        });
    }

    //提交
    public handleAction(action: any) {
        if (action === "confirm") {
            if (this.callback(action)) this.destroyVm();
        } else {
            this.callback(action);
            this.destroyVm();
        }
    }

    // 销毁
    public destroyVm() {
        this.$el.classList.remove("animate__fadeIn");
        this.$el.classList.add("animate__fadeOut");
        setTimeout(() => {
            this.show = false;
            this.$el && this.$el.parentNode.removeChild(this.$el);
        }, 500);
    }

    //初始位置
    private moveOffset: any = {
        x: null,
        y: null,
    };
    //背景单击事件
    public modalBackdropClick() {
        const isClick = this.$el.getAttribute("flag");
        if (isClick === "true") this.destroyVm();
    }
    //鼠标按下事件
    public mouseDownHandDoc(event: any) {
        this.moveOffset.x = event.pageX - this.$el.offsetLeft;
        this.moveOffset.y = event.pageY - this.$el.offsetTop;
        event.currentTarget.style.cursor = "move";
        window.onmousemove = this.mouseMoveHandDoc;
        this.$el.setAttribute("flag", "false");
        const firstTime = new Date().getTime();
        document.onmouseup = () => {
            document.onmouseup = null;
            // onmouseup 时的时间，并计算差值
            const lastTime = new Date().getTime();
            if (lastTime - firstTime < 200) {
                this.$el.setAttribute("flag", "true");
            }
        };
    }
    //鼠标移动事件
    public mouseMoveHandDoc(event: any) {
        this.$el.style.top = event.pageY - this.moveOffset.y + "px";
        this.$el.style.left = event.pageX - this.moveOffset.x + "px";
    }
    //鼠标抬起事件
    public mouseUpHandDoc(event: any) {
        window.onmousemove = null;
        event.currentTarget.style.cursor = "move";
    }
}

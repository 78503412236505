import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-button-tooltip",
    props: {
        content: {
            type: String,
            default: "提示文字",
        },
        placement: {
            type: String,
            default: "bottom",
        },
        effect: {
            type: String,
            default: "dark",
        },
        btnClass: {
            type: String,
            default: "btn btn-themes",
        },
    },
    emits: ["btnClick"],
})
export default class ButtonTooltip extends Vue {
    public btnClick() {
        this.$emit("btnClick");
    }
}

export default {
    setNodeMap: (state: any, payload: any): void => {
        state.nodeMap = payload;
    },
    setEdit: (state: any, payload: any): void => {
        state.isEdit = payload;
    },
    setSelectedNode: (state: any, payload: any): void => {
        state.selectedNode = payload;
    },
    setSelectFormItem: (state: any, payload: any): void => {
        state.selectFormItem = payload;
    },
    setDesign: (state: any, payload: any): void => {
        state.design = payload;
    },
    setRunningList: (state: any, payload: any): void => {
        state.runningList = payload;
    },
    setNoTakeList: (state: any, payload: any): void => {
        state.noTakeList = payload;
    },
    setEndList: (state: any, payload: any): void => {
        state.endList = payload;
    },
    setDiagramMode: (state: any, payload: any): void => {
        state.diagramMode = payload;
    },
    setDiagramQuery: (state: any, payload: any): void => {
        state.diagramQuery = payload;
    },
    setDetailVOList: (state: any, payload: any): void => {
        state.detailVOList = payload;
    },
    setStartUser: (state: any, payload: any): void => {
        state.startUser = payload;
    },
    setFormData: (state: any, payload: any): void => {
        state.formData = payload;
    },
    setCurrentNode: (state: any, payload: any): void => {
        state.currentNode = payload;
    },
    setProcessInfo: (state: any, payload: any): void => {
        state.processInfo = payload;
    },
};

import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d833114a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = ["for"]
const _hoisted_3 = ["id", "name", "disabled"]
const _hoisted_4 = ["selected"]
const _hoisted_5 = ["value"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.ID,
          class: "select-label"
        }, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 8, _hoisted_2))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("select", {
      class: _normalizeClass({'form-control': _ctx.type === 'default', 'custom-select': _ctx.type === 'custom'}),
      id: _ctx.ID,
      name: _ctx.ID,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
      disabled: _ctx.disabled,
      onChange: _cache[1] || (_cache[1] = $event => (_ctx.onValueChange($event)))
    }, [
      _createElementVNode("option", {
        disabled: "",
        selected: _ctx.isNoneSelected
      }, "请选择", 8, _hoisted_4),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
        return (_openBlock(), _createElementBlock("option", {
          key: option.value,
          value: option.value
        }, _toDisplayString(option.label), 9, _hoisted_5))
      }), 128))
    ], 42, _hoisted_3), [
      [_vModelSelect, _ctx.value]
    ])
  ]))
}
import { Options, Vue } from "vue-class-component";

@Options({
    name: "app-dialog",
    props: {
        modelValue: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "50%",
        },
        clickClose: {
            type: Boolean,
            default: false,
        },
        closeFree: {
            type: Boolean,
            default: false,
        },
        customHeader: {
            type: Boolean,
            default: false,
        },
        showFooter: {
            type: Boolean,
            default: true,
        },
        cancelText: {
            type: String,
            default: "取 消",
        },
        okText: {
            type: String,
            default: "确 定",
        },
        border: {
            type: Boolean,
            default: true,
        },
    },
    emits: ["update:modelValue", "cancel", "ok"],
})
export default class Dialog extends Vue {
    private modelValue: any;
    get _value() {
        return this.modelValue;
    }
    set _value(val) {
        this.$emit("update:modelValue", val);
    }
}

import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createSlots as _createSlots, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_el_button = _resolveComponent("el-button")
  const _component_el_dialog = _resolveComponent("el-dialog")

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      class: "custom-dialog border",
      width: _ctx.width,
      title: _ctx.title,
      "close-on-click-modal": _ctx.clickClose,
      "destroy-on-close": _ctx.closeFree,
      modelValue: _ctx._value,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx._value) = $event))
    }, _createSlots({
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 2
    }, [
      (_ctx.customHeader)
        ? {
            name: "header",
            fn: _withCtx(({ close, titleId, titleClass }) => [
              _renderSlot(_ctx.$slots, "header", {
                close: close,
                titleId: titleId,
                titleClass: titleClass
              }, undefined, true)
            ])
          }
        : undefined,
      (_ctx.showFooter)
        ? {
            name: "footer",
            fn: _withCtx(() => [
              _createElementVNode("div", null, [
                _createVNode(_component_el_button, {
                  size: "default",
                  onClick: _cache[0] || (_cache[0] = $event => {_ctx._value = false; _ctx.$emit('cancel')})
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.cancelText), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_button, {
                  size: "default",
                  type: "primary",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.$emit('ok')))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.okText), 1)
                  ]),
                  _: 1
                })
              ])
            ])
          }
        : undefined
    ]), 1032, ["width", "title", "close-on-click-modal", "destroy-on-close", "modelValue"])
  ]))
}
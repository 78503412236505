import { Option } from "@/components/select/select";

export const NAVBAR_LIGHT_VARIANTS: Array<Option> = [
    { value: "navbar-light", label: "Light" },
    { value: "navbar-primary navbar-light", label: "Primary" },
    { value: "navbar-secondary navbar-light", label: "Secondary" },
    { value: "navbar-info navbar-light", label: "Info" },
    { value: "navbar-success navbar-light", label: "Success" },
    { value: "navbar-danger navbar-light", label: "Danger" },
    { value: "navbar-indigo navbar-light", label: "Indigo" },
    { value: "navbar-purple navbar-light", label: "Purple" },
    { value: "navbar-pink navbar-light", label: "Pink" },
    { value: "navbar-lightblue navbar-light", label: "Light Blue" },
    { value: "navbar-teal navbar-light", label: "Teal" },
    { value: "navbar-cyan navbar-light", label: "Cyan" },
    { value: "navbar-gray navbar-light", label: "Gray" },
];
export const NAVBAR_DARK_VARIANTS: Array<Option> = [
    { value: "navbar-dark", label: "Dark" },
    { value: "navbar-primary navbar-dark", label: "Primary" },
    { value: "navbar-secondary navbar-dark", label: "Secondary" },
    { value: "navbar-info navbar-dark", label: "Info" },
    { value: "navbar-success navbar-dark", label: "Success" },
    { value: "navbar-danger navbar-dark", label: "Danger" },
    { value: "navbar-indigo navbar-dark", label: "Indigo" },
    { value: "navbar-purple navbar-dark", label: "Purple" },
    { value: "navbar-pink navbar-dark", label: "Pink" },
    { value: "navbar-navy navbar-dark", label: "Navy" },
    { value: "navbar-lightblue navbar-dark", label: "Light Blue" },
    { value: "navbar-teal navbar-dark", label: "Teal" },
    { value: "navbar-cyan navbar-dark", label: "Cyan" },
    { value: "navbar-gray-dark navbar-dark", label: "Gray Dark" },
    { value: "navbar-gray navbar-dark", label: "Gray" },
];

export const THEME_DARK_SKINS: Array<Option> = [
    { value: "themes-dark-primary", label: "Primary" },
    { value: "themes-dark-secondary", label: "Secondary" },
    { value: "themes-dark-warning", label: "Warning" },
    { value: "themes-dark-info", label: "Info" },
    { value: "themes-dark-danger", label: "Danger" },
    { value: "themes-dark-success", label: "Success" },
    { value: "themes-dark-indigo", label: "Indigo" },
    { value: "themes-dark-lightblue", label: "Lightblue" },
    { value: "themes-dark-navy", label: "Navy" },
    { value: "themes-dark-purple", label: "Purple" },
    { value: "themes-dark-fuchsia", label: "Fuchsia" },
    { value: "themes-dark-pink", label: "Pink" },
    { value: "themes-dark-maroon", label: "Maroon" },
    { value: "themes-dark-lime", label: "Lime" },
    { value: "themes-dark-teal", label: "Teal" },
    { value: "themes-dark-olive", label: "Olive" },
    { value: "themes-dark-red", label: "Red" },
    { value: "themes-dark-yellow", label: "Yellow" },
    { value: "themes-dark-blue", label: "Blue" },
    { value: "themes-dark-orange", label: "Orange" },
    { value: "themes-dark-green", label: "Green" },
];

export const THEME_LIGHT_SKINS: Array<Option> = [
    { value: "themes-light-primary", label: "Primary" },
    { value: "themes-light-secondary", label: "Secondary" },
    { value: "themes-light-warning", label: "Warning" },
    { value: "themes-light-info", label: "Info" },
    { value: "themes-light-danger", label: "Danger" },
    { value: "themes-light-success", label: "Success" },
    { value: "themes-light-indigo", label: "Indigo" },
    { value: "themes-light-lightblue", label: "Lightblue" },
    { value: "themes-light-navy", label: "Navy" },
    { value: "themes-light-purple", label: "Purple" },
    { value: "themes-light-fuchsia", label: "Fuchsia" },
    { value: "themes-light-pink", label: "Pink" },
    { value: "themes-light-maroon", label: "Maroon" },
    { value: "themes-light-lime", label: "Lime" },
    { value: "themes-light-teal", label: "Teal" },
    { value: "themes-light-olive", label: "Olive" },
    { value: "themes-light-red", label: "Red" },
    { value: "themes-light-yellow", label: "Yellow" },
    { value: "themes-light-blue", label: "Blue" },
    { value: "themes-light-orange", label: "Orange" },
    { value: "themes-light-green", label: "Green" },
];

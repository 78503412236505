import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e5013b14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  class: "select-label"
}

export function render(_ctx, _cache) {
  const _component_app_multi_select = _resolveComponent("app-multi-select")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString(_ctx.label), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_app_multi_select, {
      ref: "selectFromDbSelect",
      id: _ctx.ID,
      name: _ctx.ID,
      classes: {
            'container': 'multiselect form-control',
            'search': 'multiselect-search form-control',
            'options': 'multiselect-options dropdown-options'
        },
      placeholder: "请选择",
      noOptionsText: "暂无数据",
      noResultsText: "未搜索到数据",
      label: "name",
      modelValue: _ctx.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.value) = $event)),
      disabled: _ctx.disabled,
      delay: 1000,
      searchable: true,
      options: async function(query) {
            return await _ctx.selectRequest(query)
        },
      onSearchChange: _ctx.searchChange,
      onChange: _ctx.valueChange
    }, null, 8, ["id", "name", "modelValue", "disabled", "options", "onSearchChange", "onChange"])
  ]))
}
import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-da75df02"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "ID"
}
const _hoisted_3 = {
  class: "input-group",
  style: {"padding":"0.375rem 0.75rem","border":"1px solid #ced4da","line-height":"1.5","border-radius":"0.25rem"}
}

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.disabled)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.max, (i) => {
            return (_openBlock(), _createBlock(_component_svg_icon, {
              style: {"color":"#aaaaaa"},
              key: i,
              name: i <= _ctx.modelValue ? 'star' : 'star-outline',
              size: _ctx.size
            }, null, 8, ["name", "size"]))
          }), 128))
        : (_ctx.readonly)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.max, (i) => {
              return (_openBlock(), _createBlock(_component_svg_icon, {
                style: _normalizeStyle({color: _ctx.color}),
                key: i,
                name: i <= _ctx.modelValue ? 'star' : 'star-outline',
                size: _ctx.size
              }, null, 8, ["style", "name", "size"]))
            }), 128))
          : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.max, (i) => {
              return (_openBlock(), _createBlock(_component_svg_icon, {
                style: _normalizeStyle({color: _ctx.color}),
                key: i,
                name: i <= _ctx.modelValue ? 'star' : 'star-outline',
                size: _ctx.size,
                onClick: $event => (_ctx.onValueChange(i))
              }, null, 8, ["style", "name", "size", "onClick"]))
            }), 128))
    ])
  ]))
}
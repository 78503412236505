import { Options, Vue } from "vue-class-component";
import Multiselect from "@vueform/multiselect";
import { v4 as uuidv4 } from "uuid";

//https://github.com/vueform/multiselect
@Options({
    name: "app-select-from-db",
    components: {
        "app-multi-select": Multiselect,
    },
    props: {
        modelValue: [String, Number],
        label: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        searchApi: {
            type: Object,
            default: null,
        },
        requestCallBack: Function,
    },
    emits: ["update:change", "update:modelValue", "update:changeName", "update:changeValue"],
})
export default class SelectFromDb extends Vue {
    private ID: string = uuidv4();
    private refSelectFromDb: any = null;
    private modelValue: any = null;
    private searchApi: any = null;
    private requestCallBack: any = null;
    private list: any = [];

    public created() {
        this.refSelectFromDb = this.$refs.selectFromDb;
    }

    get value(): any {
        return this.modelValue;
    }

    set value(value: any) {
        this.$emit("update:modelValue", value);
    }

    public clear() {
        (this.$refs.selectFromDbSelect as any).clear();
    }
    public refreshOptions() {
        (this.$refs.selectFromDbSelect as any).refreshOptions();
    }

    // api: (query: any) => API.idSelect(query),//api接口
    // status: 'org_status',//状态字段，只查询状态值为1的数据
    // name: 'org_name',//名称字段
    // id: 'org_code',//ID字段
    // cid: this.editModalItem.orgParentCode,//当前选项CODE
    // mid: this.editModalItem.orgCode,//当前数据CODE
    // extend: false//是否显示扩展选项
    // cType: 0,1,2//0=功能菜单，1=菜单，2=模块(菜单模块专用)
    public async selectRequest(query: any): Promise<any> {
        if (this.$tools.isEmpty(this.searchApi.name)) return [];
        if (this.$tools.isEmpty(this.searchApi.id)) return [];
        if (this.$tools.isEmpty(this.searchApi.status)) return [];
        if (this.searchApi.status === 0) return [];
        //let list: any = [];
        await this.searchApi.api(Object.assign({ key: query }, this.searchApi)).then((res: any) => {
            this.list = res.data.records;
        });
        if (this.requestCallBack) this.requestCallBack(this.list);
        return this.list;
    }

    public searchChange(query: any) {
        if (this.$tools.isEmpty(query)) return;
    }

    public valueChange(query: any) {
        let obj: any = "";
        for (const key in this.list) {
            if (query === this.list[key]["value"]) {
                obj = this.list[key];
                break;
            }
        }
        this.$emit("update:change", obj);
        this.$emit("update:modelValue", query);
        this.$emit("update:changeValue", query);
        if (this.$tools.isEmpty(obj.name)) return;
        const nameArray = obj.name.split("-");
        const name = nameArray[nameArray.length - 1];
        this.$emit("update:changeName", name);
    }
}

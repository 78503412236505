import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a1e2793"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-control custom-switch custom-themes" }
const _hoisted_2 = ["id", "checked"]
const _hoisted_3 = ["for"]

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      type: "checkbox",
      id: _ctx.ID,
      class: "custom-control-input",
      checked: _ctx.modelValue,
      onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event)))
    }, null, 40, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.ID,
      class: "custom-control-label"
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 8, _hoisted_3)
  ]))
}
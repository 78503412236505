import { Options, Vue } from "vue-class-component";
import { v4 as uuidv4 } from "uuid";

@Options({
    name: "app-input-auto",
    props: {
        modelValue: [String, Number],
        label: String,
        type: String,
        placeholder: String,
        autocomplete: String,
        num: {
            type: Number,
            default: 32,
        },
        icon: {
            type: String,
            default: "key",
        },
        disabled: {
            type: Boolean,
            default: () => false,
        },
        readonly: {
            type: Boolean,
            default: () => false,
        },
    },
    emits: ["update:modelValue", "update:changeValue", "buttonSubmit"],
})
export default class InputAuto extends Vue {
    private ID: string = uuidv4();
    private modelValue: any;
    private num: number;

    get value(): any {
        return this.modelValue;
    }

    set value(val: any) {
        this.$emit("update:modelValue", val);
    }

    public onValueChange(event: any) {
        this.$emit("update:modelValue", event.target.value);
        this.$emit("update:changeValue", event.target.value);
    }

    public buttonSubmit(event: any) {
        this.value = this.$tools.generateRandomString(this.num);
    }
}

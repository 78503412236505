import LoginInfo from "@/entity/LoginInfo";

export default {
    loginInfo: (state: any): LoginInfo => state.loginInfo,
    tableRows: (state: any): number => state.tableRows,
    darkModeSelected: (state: any): boolean => state.darkMode,
    darkMenuSelected: (state: any): boolean => state.darkMenu,
    menuSidebarCollapsed: (state: any): boolean => state.menuSidebarCollapsed,
    controlSidebarCollapsed: (state: any): boolean => state.controlSidebarCollapsed,
    menuAndHeaderCollapsed: (state: any): boolean => state.menuAndHeaderCollapsed,
    screenSize: (state: any): boolean => state.screenSize,
    themeSkin: (state: any): string => state.themeSkin,
    navbarVariant: (state: any): string => state.navbarVariant,
    //sidebarSkin: (state: any): string => state.sidebarSkin,
    siteTitle: (state: any): string => state.siteTitle,
    pageTitle: (state: any): string => state.pageTitle,
};

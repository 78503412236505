import { createStore } from "vuex";
import authModule from "./auth";
import uiModule from "./ui";
import routerModule from "./router";
import flowableModule from "./flowable";

export default createStore({
    modules: {
        auth: authModule,
        ui: uiModule,
        router: routerModule,
        flowable: flowableModule,
    },
});

export default class UserInfo {
    userId: number;
    postId: number;
    postIdName: string;
    deptId: number;
    deptIdName: string;
    userCode: string;
    userName: string;
    userIcon: string;

    userIsOne: number;
    userDdOpenId: string;
    userWxMiniOpenId: string;
    userWxMpOpenId: string;
    userWxUnionId: string;
    realName: string;
    roleIdList: string;
    userPhone: string;

    orgCode: number;
    orgName: string;
    orgTypeId: number;
    orgTypeIdName: string;
    orgParentCode: number;
    orgParentCodeName: string;
    router: string;
}

import { toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-340772ce"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-group" }
const _hoisted_2 = {
  key: 0,
  for: "ID"
}
const _hoisted_3 = { class: "input-group" }
const _hoisted_4 = ["id", "type", "placeholder", "autocomplete", "value", "disabled", "readonly"]
const _hoisted_5 = {
  key: 0,
  class: "input-group-append"
}
const _hoisted_6 = {
  key: 0,
  class: "input-group-text"
}
const _hoisted_7 = {
  key: 1,
  class: "input-group-text"
}

export function render(_ctx, _cache) {
  const _component_svg_icon = _resolveComponent("svg-icon")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.label), 1),
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("input", {
        id: _ctx.ID,
        type: _ctx.type,
        class: "form-control",
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocomplete,
        value: _ctx.modelValue,
        disabled: _ctx.disabled,
        readonly: _ctx.readonly,
        onChange: _cache[0] || (_cache[0] = $event => (_ctx.onValueChange($event)))
      }, null, 40, _hoisted_4),
      (_ctx.icon || _ctx.unit || _ctx.buttonText)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_ctx.icon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createVNode(_component_svg_icon, { name: _ctx.icon }, null, 8, ["name"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.unit)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.unit), 1))
              : _createCommentVNode("", true),
            (_ctx.buttonText)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "input-group-text hand",
                  onClick: _cache[1] || (_cache[1] = (...args) => (_ctx.buttonSubmit && _ctx.buttonSubmit(...args)))
                }, _toDisplayString(_ctx.buttonText), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}